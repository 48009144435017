import React, { Fragment } from 'react'
import { Button, Col, Row } from 'antd'

export const Documents = () => {
  return (
    <Fragment>
      <div className='container page'>
        <h1 className='text-center'>Documents</h1>
        <div className='text-center'>
          <p>
            The University of Calgary Spine Surgery Program aspires for excellence across
            all facets of clincal care including education and research. Our Annual
            Reports and Newsletters document and summarize our goals and achievements in
            our pursuit.{' '}
          </p>
        </div>
        <div className='section-inner'>
          <Row gutter={[32, 12]} style={{ marginBottom: '64px' }} align='top'>
            <Col span={12} align='middle'>
              <h4 className='muted'>Newsletters</h4>
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/newsletter_oct2024.pdf'
                target='_blank'
              >
                October 2024
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/newsletter_apr2024.pdf'
                target='_blank'
              >
                April 2024
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/newsletter_oct2023.pdf'
                target='_blank'
              >
                October 2023
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/newsletter_feb2022.pdf'
                target='_blank'
              >
                February 2022
              </Button>
            </Col>
            <Col span={12} align='middle'>
              <h4 className='muted'>Annual Reports</h4>
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/annual_report_2021_2022.pdf'
                target='_blank'
              >
                2021-2022
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/annual_report_2020_2021.pdf'
                target='_blank'
              >
                2020-2021
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/annual_report_2019_2020.pdf'
                target='_blank'
              >
                2019-2020
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/annual_report_2018_2019.pdf'
                target='_blank'
              >
                2018-2019
              </Button>
              <br />
              <Button
                type='link'
                size='large'
                style={{ margin: '0.5rem' }}
                href='/pdf/annual_report_2017_2018.pdf'
                target='_blank'
              >
                2017-2018
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
